import { Cart, PaymentMethod } from "../cart";
import { Order } from "../order";
import { Product, ProductVariant } from "../product";

export function FacebookPixel({ data }: any) {
  const options = {
    autoConfig: true,
    debug: false,
  };
  if (data.length !== 0) {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        for (const id of data) {
          ReactPixel.init(id["code"], undefined, options);
        }
        ReactPixel.pageView();
      });
  }
}

export function FacebookPixelPageView() {
  import("react-facebook-pixel")
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.pageView();
    });
}

export function FacebookPixelProduct({
  product,
  variant,
}: {
  product: Partial<Product>;
  variant: Partial<ProductVariant>;
}) {
  import("react-facebook-pixel")
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track("ViewContent", {
        content_type: "product",
        content_ids: [product?.sku],
        value: product?.price_range?.minimum_price?.final_price?.value,
        content_name: product?.name,
        currency:
          product?.price_range?.minimum_price?.final_price?.currency,
      });
    });
}

export function FacebookPixelAddToCart({
  product,
  variant,
  qty,
}: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) {
  import("react-facebook-pixel")
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track("AddToCart", {
        content_type: "product",
        content_ids: [product?.sku],
        content_name: product?.name,
        value: product?.price_range?.minimum_price?.final_price?.value,
        currency:
          product?.price_range?.minimum_price?.final_price?.currency,
      });
    });
}

export function FacebookPixelInitiateCheckout({
  cartInfo,
}: {
  cartInfo: Cart;
}) {
  import("react-facebook-pixel")
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track("InitiateCheckout", {
        content_ids: cartInfo.items?.map((i) => i.product?.sku),
        content_type: "product",
        value: cartInfo?.prices?.grand_total?.value,
        num_items: cartInfo.items?.length,
        currency: cartInfo?.prices?.grand_total?.currency,
      });
    });
}

export function FacebookPixelPurchase({ order }: { order: Partial<Order> }) {
  import("react-facebook-pixel")
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track("Purchase", {
        content_type: "product",
        value: order.prices?.grand_total?.value ?? "",
        currency: order?.prices?.grand_total?.currency,
        content_ids: order.items?.map((i) => i.product?.sku),
        num_items: order.items?.length,
      });
    });
}

export function FacebookPixelSearch({
  keyword,
  data,
}: {
  keyword: string;
  data: Product[];
}) {
  import("react-facebook-pixel")
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track("Search", {
        search_string: keyword,
        content_type: "product",
        currency: "USD",
        content_ids: data?.map((item) => item.sku),
      });
    });
}

export function FacebookPixelCategory({
  keyword,
  data,
}: {
  keyword: string;
  data: Product[];
}) {
  import("react-facebook-pixel")
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track("ViewCategory", {
        content_type: "product",
        content_category: keyword,
        content_ids: data?.map((item) => item.sku),
      });
    });
}

export function FacebookPixelAddPaymentInfo({
  cart,
  paymentMethod,
}: {
  cart: Cart;
  paymentMethod: PaymentMethod;
}) {
  import("react-facebook-pixel")
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track("AddPaymentInfo", {
        value: paymentMethod.code,
        currency: cart?.prices?.grand_total?.currency,
        content_ids: cart?.items?.map(
          (item) => item.selectedVariant?.product?.sku
        ),

        contents: cart?.items?.map((item) => {
          return {
            id: item.selectedVariant?.product?.sku,
            name: item.selectedVariant?.product?.name,
            quantity: item.quantity,
            price:
              item.selectedVariant?.product?.price_range?.minimum_price
                ?.final_price?.value,
          };
        }),
      });
    });
}
export function FacebookPixelAddToWishList({
  product,
}: {
  product: Partial<Product>;
}) {
  import("react-facebook-pixel")
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track("AddToWishlist", {
        content_ids: [product?.sku],
        value: product?.price_range?.minimum_price?.final_price?.value,
        currency: product?.price_range?.minimum_price?.final_price?.currency,
        contents: [
          {
            id: product?.sku,
            name: product?.name,
            price: product?.price_range?.minimum_price?.final_price?.value,
          },
        ],
      });
    });
}
