import * as fs from "fs";

import path from "path";

import { isEmpty, isUndefined } from "lodash";

export const getTheme = () => {
  return process.env.NEXT_PUBLIC_THEME || "base";
};

export const getConfig = (componentName: string) => {
  if (!componentName) {
    return {};
  }
  try {
    return (
      require(`~/theme/${getTheme()}/config.ts`).default[componentName] || {}
    );
  } catch (error) {
    return require(`~/theme/base/config.ts`).default[componentName] || {};
  }
};

export const checkFileExists = (filePath: string) => {
  try {
    const resolvedPath = path.resolve(filePath);
    return fs.existsSync(resolvedPath);
  } catch (error) {
    console.error("Error checking file existence:", error);
    return false;
  }
};

export const getUrlByTheme = (pathUrl: string): string => {
  if (!process.env.NEXT_PUBLIC_URL || process.env.NEXT_PUBLIC_URL === "/") {
    return pathUrl;
  }

  return process.env.NEXT_PUBLIC_URL + pathUrl;
};

// Call the function
export const getUrlCustom = ({
  pathname,
  query,
}: {
  pathname: string;
  query?: {
    [key: string]: any;
  };
}) => {
  let resultUrl = pathname;

  if (!isEmpty(query) && !isUndefined(query)) {
    let queryArray: string[] = [];
    Object.entries(query).map(([key, value]) => {
      if (!isEmpty(value + "")) {
        if (pathname.indexOf(`[${key}]`) > -1) {
          resultUrl = resultUrl.replace(`[${key}]`, value as string);
        } else if (!!value) {
          queryArray?.push(`${key}=${value}`);
        }
      }
    });
    const newQuery = queryArray?.filter((i) => i !== "");

    if (!!newQuery.length) {
      resultUrl = `${resultUrl}?${newQuery.join("&")}`;
    }
  }

  return resultUrl;
};
