import { toast } from 'react-toastify'

interface IToastAlert {
  type: 'success' | 'info' | 'error'
  message: string
}

export const toastAlert = ({ type = 'info', message }: IToastAlert) => {
  if (!message || !message.length) return
  // const options = {
  //   position: 'bottom-left',
  //   autoClose: 2000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  // }

  toast[type](message)
}
