import { flatten, get, isEmpty, uniq } from 'lodash';
import { NodesResponse, ProductItem } from '~/data/home/interface';
import { Product, ProductVariant } from '~/services/product';
import { extractCurrencySymbol } from './number';
import { CartResponse, CartResponseItem, Variants } from '~/data/cart';
import { Cart, CartItem, ShippingAddress } from '~/services/cart';
import { trackingRemoveItemFromCart } from '~/services/tracking';

export const transformProductItems = (productItems: ProductItem[], theme?: string): Product[] => {
    if (isEmpty(productItems) || !productItems) return [];
    switch (theme) {
        default: {
            return productItems.map((item: any) => {
                const newItems: Product = transformProductItem(item, theme);
                return newItems;
            });
        }
    }
};

export const transformPriceRange = (priceRange: any): any => {
    if (isEmpty(priceRange)) return {};
    return {
        final_price: {
            value: priceRange?.final_price?.value,
            currency: priceRange?.final_price?.currency,
            symbol: extractCurrencySymbol(get('priceRange.final_price.currency', 'USD') || '')
        },
        regular_price: {
            value: get(priceRange, 'regular_price.value', 0),
            currency: priceRange?.regular_price?.currency,
            symbol: extractCurrencySymbol(get('priceRange.regular_price.currency', 'USD') || '')
        },
        discount: {
            amount_off: get(priceRange, 'discount.amount_off', 0),
            percent_off: get(priceRange, 'discount.percent_off', 0)
        }
    };
};

export const transformProductItem = (product: ProductItem, theme?: string): Product => {
    if (isEmpty(product)) return {} as Product;
    const result = {
        ...product,
        id: product.id || '',
        sku: product.sku || '',
        name: product.name || '',
        description: product?.description || '',
        categories: product?.categories || [],
        slug: product.url_key,
        image: product?.thumbnail || product?.small_image || { url: '', label: '' },
        small_image: product?.small_image || '',
        configurable_options:
            product?.configurable_options?.map((item: any) => {
                return {
                    ...item,
                    values: item.values?.map((value: any) => {
                        const newSwatch =
                            value?.swatch_data?.__typename === 'ImageSwatchData'
                                ? {
                                      ...value?.swatch_data,
                                      value: process.env.NEXT_PUBLIC_MEDIA_PATH + value?.swatch_data?.value
                                  }
                                : value?.swatch_data;
                        return {
                            ...value,
                            swatch_data: newSwatch
                        };
                    })
                };
            }) || [],
        variants: (product.variants as any[]) || [],
        images: product?.media_gallery?.map((item: any) => item.url) || [],
        type: 'simple',
        badges: [],
        price_range: {
            maximum_price: transformPriceRange(product?.price_range?.maximum_price || ({} as any)),
            minimum_price: transformPriceRange(product?.price_range?.minimum_price || ({} as any))
        },
        rating_summary: product.reviews?.summary ? (product.reviews?.summary * 5) / 100 : 0,
        review_count: product.reviews?.total_count || 0
    };

    return result as any;
};

export function matchEmphasize({ name, search }: { name?: string; search?: string }) {
    if (!search || !name) return name;
    let arraySearch = [search];
    if (search) {
        arraySearch = search.split(' ');
    }

    const regExp = new RegExp(`(${arraySearch.join('|')})`, 'gi');

    return name.replace(regExp, (match: string) => '<strong class="text-primary">' + match + '</strong>');
}

export const transformCartItems = (cartItems: CartResponseItem[]): CartItem[] => {
    if (isEmpty(cartItems)) return [];
    return cartItems.map((item: CartResponseItem) => transformCartItem(item));
};

export const transformCartItem = (cartItem: CartResponseItem): CartItem => {
    if (isEmpty(cartItem)) return {} as any;

    const selectedVariant: Partial<Variants> =
        cartItem.product?.variants?.find((v: any) => {
            return v.attributes?.every((item: any) => {
                return cartItem.configurable_options?.some(
                    (option: any) => option.configurable_product_option_value_uid === item.uid
                );
            });
        }) || ({} as Variants);

    const result: CartItem = {
        id: cartItem.id || '',
        uid: cartItem.uid || '',
        product: transformProductItem(cartItem.product) as any,
        selectedVariant: {
            attributes: selectedVariant.attributes || [],
            product: {
                ...(selectedVariant?.product || {}),
                id: selectedVariant?.product?.id || 0,
                name: selectedVariant?.product?.name || '',
                price_range: {
                    maximum_price: transformPriceRange({} as any),
                    minimum_price: transformPriceRange(
                        selectedVariant?.product?.price_range?.minimum_price || ({} as any)
                    )
                },
                sku: selectedVariant?.product?.sku || '',
                image: selectedVariant?.product?.thumbnail || { url: '', label: '' }
            }
        },
        quantity: cartItem.quantity,
        selected: false
    };
    return result;
};

export const getMaxRating = (arrayReviews: any[]) => {
    let maxRating = 0;
    if (isEmpty(arrayReviews)) {
        return 0;
    }
    arrayReviews.map((item) => {
        if (item.average_rating > maxRating) {
            maxRating = item.average_rating;
        }
    });

    return Math.round((5 * maxRating) / 100);
};

export const transformContentCms = (text: string): any[] => {
    if (!text) return [];

    const result = [];
    const regex = /<h2.*?>(.*?)<\/h2>(?:<strong>(.*?)<\/strong>(.*?)<\/div>)+/g;
    let match;

    while ((match = regex.exec(text)) !== null) {
        const [, h2Title, strongTitles, strongContents] = match;
        const children = [];

        if (strongTitles && strongContents) {
            const strongTitleArray = strongTitles.split('</strong>');
            const strongContentArray = strongContents.split('</div>');
            for (let i = 0; i < strongTitleArray.length - 1; i++) {
                const strongTitle = strongTitleArray[i].replace('<strong>', '').trim();
                const strongContent = strongContentArray[i].trim();
                children.push({ title: strongTitle, content: [strongContent] });
            }
        }

        result.push({
            title: h2Title.trim(),
            children: children.length > 0 ? children : undefined,
            content: children.length === 0 ? [strongContents.trim()] : undefined
        });
    }

    const contactSection = {
        title: 'Contact Information',
        content: [
            'Follow us on social media to not miss out new releases, giveaways as well as promotions.',
            'Facebook: https://www.facebook.com/Beenery-105800602072044',
            'Twitter: https://twitter.com/Beeneryy',
            'Pinterest: https://www.pinterest.com/beeneryy/_saved/',
            'YouTube: https://www.youtube.com/channel/UC_harM1h9s6FhFXX8emifFw',
            'Trustpilot: https://www.trustpilot.com/review/beenery.com',
            'Email: support@beenery.com',
            'Address:',
            '2109 Gateway Court, Louisville KY 40299',
            'Nhon Hoa, Hoa Dinh, Cho Gao, Tien Giang, VN 860000',
            'Marketing Merchant: 213 Chu Van An, ward 26, Binh Thanh district, HCM city, Viet Nam',
            'Phone: +1 (910) 250 9291'
        ]
    };

    result.push(contactSection);

    return result;
};

export const transformNodeToSubMenu = (node: NodesResponse): any => {
    return {
        ...node,
        title: node.title,
        type: node.type,
        items: node.children?.map((item: any) => {
            return transformNodeToSubMenu(item);
        }),
        content: node.content,
        badges: node.label
            ? [
                  {
                      title: node.label,
                      color: node.label_color,
                      background: node.label_background
                  }
              ]
            : []
    };
};

export const transformNodeToMenu = (nodes: NodesResponse): any => {
    return {
        ...nodes,
        url: nodes.url,
        title: nodes.title,
        badge: !!nodes?.label && {
            title: nodes.label,
            color: nodes.label_color,
            background: nodes.label_background
        },
        submenu: nodes.children?.map((item: any) => transformNodeToSubMenu(item))
        // [
        //         nodes.children?.filter((item:any) => !["product" , "cms_block"].includes(item.type)).map((item:any) => transformNodeToSubMenu(item)),
        //         nodes.children?.filter((item:any) =>["product" , "cms_block"].includes(item.type)).length > 0 ?
        //         {
        //             title:nodes.title,
        //             type:"product",
        //             id:nodes.node_id,
        //             products:nodes.children?.filter((item:any) => item.type === "product").map((item:any) => transformProductItem(item)),
        //         } : {} as any

        // ].filter((item:any) => !isEmpty(item))
    };
};

export const transformNodesToMenu = (nodes: NodesResponse[]): any[] => {
    if (isEmpty(nodes)) return [];
    return nodes.map((cItem: NodesResponse) => transformNodeToMenu(cItem));
};

export const transFormCart = (data: { cart: CartResponse }) => {
    const prevVersion = localStorage.getItem('version');

    // if(typeof window !==   "undefined" && (!prevVersion ||  prevVersion !== process.env?.NEXT_PUBLIC_VERSION)){
    //   localStorage.removeItem("cart")
    //   localStorage.setItem("version", process.env.NEXT_PUBLIC_VERSION || "1.0.0")
    // }
    const cartTemp: any = data.cart;

    const result: Cart = {
        ...cartTemp,
        //   cartId: cartId as string,
        payment_method: get(data?.cart, 'available_payment_methods', []),
        items: transformCartItems(data?.cart?.items || []),
        email: cartTemp.email,
        billing_address: !isEmpty(data?.cart?.billing_address)
            ? {
                  ...data?.cart?.billing_address,
                  street: data?.cart?.billing_address?.street?.[0] || undefined,
                  country_code: data?.cart?.billing_address?.country?.code,
                  region_code: data?.cart?.billing_address?.region?.code || '',
                  region_id: data?.cart?.billing_address?.region?.region_id || null
              }
            : {},
        shipping_addresses: !!data?.cart?.shipping_addresses?.length
            ? data?.cart?.shipping_addresses?.map((shipAddress: any) => ({
                  ...shipAddress,
                  street: shipAddress?.street?.[0] || undefined,
                  country_code: shipAddress?.country?.code,
                  region_code: shipAddress?.region?.code || '',
                  region_id: shipAddress?.region?.region_id || null
              }))
            : [],
        shipping_fee: get(data?.cart, 'shipping_addresses[0].selected_shipping_method.amount.value', 0),
        selected_payment_method: get(data?.cart, 'selected_payment_method', {}),
        selected_shipping_method: get(data?.cart, 'shipping_addresses[0].selected_shipping_method', {}),
        shipping_methods: get(data?.cart, 'shipping_addresses[0].available_shipping_methods', []),
        loading: false
    };
    return result;
};
