import { atom, selector } from "recoil";
import { StoreConfig } from "./store-config.type";

const store = atom<StoreConfig>({
  key: "store-config",
  default: {} as StoreConfig,
});

const releateListByPosition = selector({
  key: "releated-list-by-position",
  get: ({ get }) => {
    const currentStore = get(store);
    const { relatedRules } = currentStore;

    let relatedByPosition: any = {};
    relatedRules?.map((item: any) => {
      if (!!relatedByPosition[item.block_position]) {
        relatedByPosition[item.block_position].push(item);
      } else {
        relatedByPosition[item.block_position] = [item];
      }
    });

    return {
      relatedByPosition,
      // totalSelected,
    };
  },
});

export default { store, releateListByPosition };
