import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { QUERY_GET_MARKETING_CONFIG_GQL } from "~/data/home";
import storeConfigAtom from "~/services/store-config/store-config.atom";
import InitialTracking from "~/services/tracking/InitialTracking";

type Props = {};

const MaketingLayout = (props: Props) => {
  const setStoreConfig = useSetRecoilState(storeConfigAtom.store);
  const { data: marketingConfig } = useQuery(QUERY_GET_MARKETING_CONFIG_GQL);

  useEffect(() => {
    setStoreConfig((prev) => ({
      ...prev,
      marketingConfig: marketingConfig?.MarketingConfig || {},
    }));
  }, [marketingConfig]);
  return (
    <div>
      <InitialTracking mktConfig={marketingConfig?.MarketingConfig} />
    </div>
  );
};

export default MaketingLayout;
