export const localStorageEffect =
    (key: string) =>
    ({ setSelf, onSet }: any) => {
        if (typeof window !== 'undefined') {
            const savedValue = localStorage.getItem(key);
            if (savedValue != null) {
                setSelf(JSON.parse(savedValue));
            }
            onSet((newValue: any) => {
                if (!newValue) {
                    localStorage.removeItem(key);
                } else {
                    localStorage.setItem(key, JSON.stringify(newValue));
                }
            });
        }
    };
