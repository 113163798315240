import React from "react";

interface AppContextValues {
  openSearch: boolean;
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = React.createContext<AppContextValues>({
  openSearch: false,
  setOpenSearch: () => {},
});
