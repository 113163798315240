import { useLazyQuery } from '@apollo/client'
import _get from 'lodash/get'
import { useEffect, useRef, useState } from 'react'

import { RECENTLY_PRODUCT } from '~/constants/localstore'
import { Product } from '~/services/product'
import { transformProductItems } from '~/utils/transform'
import { QUERY_GET_PRODUCT_RELATED_BY_SKU_RECENTLY, QUERY_OPTIMISTIC_RECENTLY_PRODUCT_GQL } from './gql'
import { ProductItem, Products } from './interface'


type Props = {
  currentSKU?: string
  url_Key?: string
  skip?: boolean
}
type UseRecentlyViewProducts<TData> = {
  called: boolean
  loading: boolean
  productItems: TData
}
export const useRecentlyViewProducts = ({
  currentSKU = '',
  url_Key = '',
  skip
}: Props): UseRecentlyViewProducts<Product[]> => {

  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState<
  ProductItem[]
  >([])
  const  defaultList = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem(RECENTLY_PRODUCT) || '[]') : []
  const [listSKURecentlyView, setLisSKURecentlyView] = useState<string[]>(defaultList)
  const [isFetched, setIsFetched] = useState(false)

  const conditionKeyRelated = 'related2'

  const [queryGetRelatedProduct, { loading: loadingRelated }]: any =
    useLazyQuery(QUERY_GET_PRODUCT_RELATED_BY_SKU_RECENTLY, {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const relatedProducts:ProductItem[] = _get(res , "relatedProduct.items" , []) || []
        indexGetRecently.current = indexGetRecently.current + 1
        const calculateList:string[] = relatedProducts.map((item: any) => item.sku)
          .slice(-(10 - listSKURecentlyView.length))
        setLisSKURecentlyView((prev:string[]) => [...prev, ...calculateList])
      },
      onError: ({ message }) => {
        console.error(message, 'QUERY_GET_PRODUCT_RELATED_BY_SKU_RECENTLY')
      },
      context:{fetchOptions: { method: "GET" }}

    })

  const [queryRecentlyProduct, { loading: loadingRecentProduct , called}] =
    useLazyQuery<Products>(QUERY_OPTIMISTIC_RECENTLY_PRODUCT_GQL, {
      // fetchPolicy: 'network-only',
      context:{fetchOptions: { method: "GET" }},
      onCompleted: (res) => {

        const products:ProductItem[] = _get(res, 'products.items', [])
        const sortProducts: ProductItem[] = []
        const displaySku =
          listSKURecentlyView?.filter((i) => i != currentSKU)?.splice(-10) || []
        products.forEach((product:ProductItem, index:number) => {
          const indexProduct = displaySku.findIndex(
            (item) => item === product.sku,
          )
          if (indexProduct != -1) {
            sortProducts[indexProduct] = product
          }
        })
        const newProducts = sortProducts.filter((i) => i.url_key != url_Key)
        setRecentlyViewedProducts(newProducts)
        
        if(newProducts.length > 0){
          // queryActualRecentlyProduct({
          //   variables: {
          //     skus: newProducts.slice(-10)?.map((item) => item.sku) || [],
          //   },
          // })
        }
        setIsFetched(true)
      },
      onError: ({ message }) => {
        console.error(message, 'QUERY_OPTIMISTIC_RECENTLY_PRODUCT_GQL')
      },
    })

  
  const indexGetRecently = useRef(0)

  useEffect(() => {
    if(!!skip) return
    // Check list Sku recently: if < 5 get more sku by the way get related product by sku exist, until list sku = 5 items.
    // if > 5 , get products by 10 sku recent ly
    if (listSKURecentlyView?.length > 0) {
      const listSKU = listSKURecentlyView
      const SkuByIndex = listSKU[indexGetRecently.current] || null
      if (listSKU.length <= 5 && !!SkuByIndex) {
        // get more sku by sku exist, begin first item , if enough continue get next item
        queryGetRelatedProduct({
          variables: {
            sku: listSKU[indexGetRecently.current],
            code: conditionKeyRelated,
          },
        })
        //   if list sku > 5 get recently product
      } else {
        const tempSKURecently = listSKU.slice(-10)
        queryRecentlyProduct({
          variables: {
            recentViewSKU: tempSKURecently,
          },
        })
      }
    }
  }, [listSKURecentlyView, queryRecentlyProduct, skip])

 
  // useEffect(() => {
  //   if (isFetched && !skip && listSKURecentlyView.length > 0) {
  //     const timeout = setTimeout(() => {
  //       queryActualRecentlyProduct({
  //         variables: {
  //           skus: listSKURecentlyView.slice(-10),
  //         },
  //       })
  //       clearTimeout(timeout)
  //     }, 2000)
  //   }
  // }, [isFetched, listSKURecentlyView, queryActualRecentlyProduct, skip])
  

  return {
    called,
    loading: loadingRecentProduct || loadingRelated,
    productItems: transformProductItems(recentlyViewedProducts),
  }
}


