import { CountryResponse } from "~/data/cart/interfaces";
import { ProgressShipping } from "~/data/product/useGetEstimateDelivery";

export enum CountryValues {
  VIET_NAM = "vietnam",
  UNITED_STATES = "united_states",
}

export interface Countries {
  loading: boolean;
  countries: Country[];
  countriesHasRegion: string[];
  countrySelected: Country;
  regionSelected: AvailableRegions;
  shippingMethodByCountry?: ShippingMethodByCountry[];
  shippingMethodSelected?: any;
  estimateShipping?: ProgressShipping[];
}

export interface ShippingMethodByCountry {
  carrier: string;
  shipping_cost: number;
  title: string;
}
export interface Country extends CountryResponse {
  value: string;
  label: string;
}

export interface AvailableRegions {
  id: any;
  name: string;
  code: string;
}
