import { useLazyQuery } from '@apollo/client'
import { toastAlert } from '~/utils/toastify'
import { GET_AVAILABLE_COUNTRIES } from './gql'
import { UseCountryResponse } from './interfaces'

export const useCountries = () => {
  return  useLazyQuery<UseCountryResponse>(GET_AVAILABLE_COUNTRIES , {
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  })
}
