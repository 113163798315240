import { atom, selector } from 'recoil';
import { transformProductItem } from '~/utils/transform';
import { Wishlist } from './wishlist.type';

const wishlist = atom<Wishlist>({
    key: 'wishlist',
    default: {
        id: null,
        items_count: 0,
        sharing_code: '',
        updated_at: ''
    }
    // effects: [localStorageEffect("cart")],
});

const WishlistSelector = selector({
    key: 'wishlist-selector',
    get: ({ get }) => {
        const currentWishList = get(wishlist);
        const wishlistItems = currentWishList.items_v2?.items?.map((wishItem) => ({
            ...wishItem,
            product: {
                ...transformProductItem(wishItem.product as any),
                id: wishItem.product?.id
            }
        }));
        return {
            ...currentWishList,
            wishlistItems
            // totalSelected,
        };
    }
});

export default { wishlist, WishlistSelector };
