import { get } from "lodash";
import { useEffect } from "react";
import ReactGA4 from "react-ga4";
import { Cart, PaymentMethod, ShippingMethod } from "../cart";
import { Order } from "../order";
import { Product, ProductVariant } from "../product";

export function GoogleAnalyticsAddToCartG4(payload: {
  product: Partial<Product>;
  variant: Partial<ProductVariant>;
  qty: number;
}) {
  const { product, variant, qty } = payload;
  const categoryMaster = product?.configurable_options
    ?.filter((item: any) => item?.attribute_code === "category_master")
    ?.map((item: any) => item?.label);
  import("react-ga4")
    .then((x) => x.default)
    .then((ReactGA4) => {
      ReactGA4.event("add_to_cart", {
        currency: product?.price_range?.minimum_price?.final_price?.currency,
        value: product?.price_range?.minimum_price?.final_price?.value,
        items: [
          {
            item_id: product.sku,
            item_name: product.name,
            price: product?.price_range?.minimum_price?.final_price?.value,
            quantity: qty,
            item_category: categoryMaster,
            item_variant: variant?.attributes
              ?.map((attr) => attr.label)
              ?.join(" / "),
          },
        ],
      });
    });
}

export function GoogleAnalyticsStartOrderG4({ cart }: { cart: Cart }) {
  import("react-ga4")
    .then((x) => x.default)
    .then((ReactGA) => {
      ReactGA.event("begin_checkout", {
        currency: cart?.prices?.grand_total?.currency,
        value: cart?.prices?.grand_total?.value || 0,
        items: cart?.items?.map((item) => {
          const item_variant = item?.configurable_options
            ?.map((options: any) => options?.value_label)
            ?.join(" ");

          return {
            item_id: item?.product?.sku,
            item_name: item?.product?.name,
            currency: get(
              item,
              "selectedVariant.product.price_range.minimum_price.final_price.currency",
              "USD"
            ),
            price: get(
              item,
              "selectedVariant.product.price_range.minimum_price.final_price.value",
              0
            ),
            item_variant: item_variant,
            quantity: item?.quantity ?? "",
          };
        }),
      });
    });
}

export function GoogleAnalyticsSelectShippingG4({
  cart,
  shippingMethod,
}: {
  cart: Cart;
  shippingMethod: ShippingMethod;
}) {
  import("react-ga4")
    .then((x) => x.default)
    .then((ReactGA) => {
      ReactGA.event("add_shipping_info", {
        value: cart?.prices?.grand_total?.value,
        currency: cart?.prices?.grand_total?.currency,
        shipping_tier: shippingMethod?.carrier_title,
        items: cart?.items?.map((item) => {
          const item_variant = item?.configurable_options
            ?.map((options: any) => options?.value_label)
            ?.join(" ");

          return {
            item_id: item?.product?.sku,
            item_name: item?.product?.name,
            currency: get(
              item,
              "selectedVariant.product.price_range.minimum_price.final_price.currency",
              "USD"
            ),
            item_variant: item_variant,
            price: get(
              item,
              "selectedVariant.product.price_range.minimum_price.final_price.value",
              0
            ),
            quantity: item?.quantity ?? "",
          };
        }),
      });
    });
}

export function GoogleAnalyticsSelectPaymentG4({
  cart,
  paymentMethod,
}: {
  cart: Cart;
  paymentMethod: PaymentMethod;
}) {
  import("react-ga4")
    .then((x) => x.default)
    .then((ReactGA) => {
      ReactGA.event("add_payment_info", {
        currency: cart?.prices?.grand_total?.currency,
        value: cart?.prices?.grand_total?.value,
        payment_type: paymentMethod?.title,
        items: cart?.items?.map((item) => {
          const item_variant = item?.configurable_options
            ?.map((options: any) => options?.value_label)
            ?.join(" ");
          return {
            item_id: item?.product?.sku,
            item_name: item?.product?.name,
            currency: get(
              item,
              "selectedVariant.product.price_range.minimum_price.final_price.currency",
              "USD"
            ),
            item_variant: item_variant,
            price: get(
              item,
              "selectedVariant.product.price_range.minimum_price.final_price.value",
              0
            ),
            quantity: item?.quantity ?? "",
          };
        }),
      });
    });
}

// chưa setup
export function GoogleAnalyticsRemoveItemG4(cart: any, itemRemove: any[]) {
  import("react-ga4")
    .then((x) => x.default)
    .then((ReactGA) => {
      ReactGA.event(
        {
          action: "click",
          category: "UX",
          label: "Remove from cart",
        },
        {
          currency: get(cart, "grand_total.currency", "USD"),
          value: get(cart, "grand_total.value", 0),
          items: itemRemove?.map((item: any) => ({
            item_id: item?.id,
            item_name: item?.product?.name,
            quantity: item?.quantity,
            price: get(
              item,
              "product.price_range.minimum_price.final_price.value",
              0
            ),
          })),
        }
      );
      ReactGA.event("remove_from_cart", {
        currency: "USD",
        value: cart?.prices?.grand_total?.value,
        items: itemRemove?.map((item: any) => ({
          item_id: item?.id,
          item_name: item?.product?.name,
          quantity: item?.quantity,
          price: get(
            item,
            "product.price_range.minimum_price.final_price.value",
            0
          ),
        })),
      });
    });
}

export function GoogleAnalyticsPurchaseG4({
  order,
}: {
  order: Partial<Order>;
}) {
  import("react-ga4")
    .then((x) => x.default)
    .then((ReactGA) => {
      ReactGA.event("purchase", {
        transaction_id: order?.order_number,
        currency: order?.prices?.grand_total?.currency,
        value: order?.prices?.grand_total?.value || 0,
        affiliation: "",
        shipping: order?.selected_shipping_method?.carrier_code ?? "",
        tax: order?.prices?.applied_taxes ?? "",
        coupon: order.applied_coupons,
        discount:
          order?.prices?.discounts?.reduce(
            (total = 0, item) => item.amount.value + total,
            0
          ) || 0, // (()),
        items: order?.items?.map((item) => {
          return {
            item_id: item?.product?.sku,
            item_name: item?.selectedVariant?.product?.name,
            currency:
              item?.selectedVariant?.product?.price_range?.minimum_price
                ?.final_price?.currency,
            affiliation: "",
            item_variant: item.selectedVariant?.attributes
              ?.map((i) => i.label)
              ?.join("/"),
            price:
              item?.selectedVariant?.product?.price_range?.minimum_price
                ?.final_price?.value || 0,
            quantity: item?.quantity ?? "",
          };
        }),
      });
    });
}

export function GoogleAnalyticsViewItemG4(payload: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) {
  const { product, variant } = payload;

  import("react-ga4")
    .then((x) => x.default)
    .then((ReactGA) => {
      ReactGA.event("view_item", {
        currency:
          variant?.product?.price_range?.minimum_price?.final_price?.currency,
        value: variant?.product?.price_range?.minimum_price?.final_price?.value,
        items: [
          {
            item_id: product?.sku,
            item_name: product?.name,
            currency:
              product?.price_range?.minimum_price?.final_price?.currency,
            price: product?.price_range?.minimum_price?.final_price?.value,
            item_category: product?.categories?.[0]?.name,
          },
        ],
        // items: product?.variants?.map((item: ProductVariant) => ({
        //     item_id: item?.product?.sku,
        //     item_name: item?.product?.name,
        //     currency:item?.product?.price_range?.minimum_price?.final_price?.currency,
        //     price: item?.product?.price_range?.minimum_price?.final_price?.value,
        //     item_category: product?.categories?.[0]?.name
        // }))
      });
    });
}

export function GoogleAnalyticsViewLisItemG4(payload: {
  products: Array<Partial<Product>>;
  listKey: string;
}) {
  const { products, listKey } = payload;

  import("react-ga4")
    .then((x) => x.default)
    .then((ReactGA) => {
      const result = {
        item_list_id: listKey,
        item_list_name: listKey,
        items: products.map((product, productIndex) => {
          const objectCategories = product.categories?.reduce(
            (acc: any, category, currentIndex) => {
              const name: string =
                currentIndex > 0
                  ? "item_category" + currentIndex
                  : "item_category";
              acc[name] = category?.name;
              return acc;
            },
            {}
          );

          return {
            item_id: product?.sku,
            item_name: product?.name,
            currency:
              product?.price_range?.minimum_price?.final_price?.currency,
            price: product?.price_range?.minimum_price?.final_price?.value,
            discount: product?.price_range?.minimum_price?.discount?.amount_off,
            quantity: 1,
            item_variant: product?.variants?.[0]?.attributes
              ?.map((i) => i.label)
              ?.join("/"),
            index: productIndex,
            ...objectCategories,
          };
        }),
      };

      ReactGA.event("view_item_list", result);
    });
}
export function GoogleAnalyticsSelectItemG4(payload: {
  product: Partial<Product>;
  listKey: string;
  index: number;
}) {
  const { product, listKey, index } = payload;

  import("react-ga4")
    .then((x) => x.default)
    .then((ReactGA) => {
      const objectCategories = product.categories?.reduce(
        (acc: any, category, currentIndex) => {
          const name: string =
            currentIndex > 0 ? "item_category" + currentIndex : "item_category";
          acc[name] = category?.name;
          return acc;
        },
        {}
      );
      ReactGA.event("select_item", {
        item_list_id: listKey,
        item_list_name: listKey,
        items: [
          {
            item_id: product?.sku,
            item_name: product?.name,
            currency:
              product?.price_range?.minimum_price?.final_price?.currency,
            price: product?.price_range?.minimum_price?.final_price?.value,
            quantity: 1,
            discount: product?.price_range?.minimum_price?.discount?.amount_off,
            item_variant: product?.variants?.[0]?.attributes
              ?.map((i) => i.label)
              ?.join("/"),
            index: index,
            ...objectCategories,
          },
        ],
      });
    });
}

// export function GoogleAnalyticsEvent({ event, data }) {
//     import('react-ga4')
//         .then((x) => x.default)
//         .then((ReactGA) => {
//             ReactGA.event(event, data);
//         });
// }

const GoogleAnalyticsG4 = ({ data }: { data: any[] }) => {
  useEffect(() => {
    if (data?.length > 0) {
      data?.map((item: any) => {
        if (item.code.includes("G-")) {
          ReactGA4.initialize(item.code);
          if (window?.location?.pathname !== "/connect-paypal") {
            ReactGA4.ga("create", item.code, "auto");
          }
        }
      });
    }
  }, [data]);

  return <></>;
};

export default GoogleAnalyticsG4;
