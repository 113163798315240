import Script from "next/script";

const GlobalSiteTag = ({ data }: any) => {
  return (
    <>
      {/* Global site tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={"https://www.googletagmanager.com/gtag/js?id=" + data["value"]}
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${data["value"]}',{'allow_enhanced_conversions':true, 'debug_mode':true});
        `}
      </Script>
    </>
  );
};
export default GlobalSiteTag;
