import { gql } from "@apollo/client";

export const GET_CUSTOMER_DATA_GQL = gql`
  query {
    customer {
      firstname
      lastname
      suffix
      email
      id
      is_subscribed
      date_of_birth
    }
  }
`;
