import { atom } from "recoil";
import { MegaMenu } from "./country.type";

const megaMenu = atom<MegaMenu>({
  key: "megaMenu",
  default: {
    loading: false,
    menu: [],
    subMenuHover: "",
    headerHeight: 65,
    // country for aLL site
  },
});

export default { megaMenu };
