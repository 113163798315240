import { isEmpty } from "lodash"
import { Country } from "~/services/countries"

export const transformCountry = (country:Country ) => {
    if(isEmpty(country) ) return {} as any
    return {
        ...country,
        value: country.id,
        label: country.full_name_english,
        available_regions: country.available_regions?.map((region:any) => ({
            ...region,
            value: region.code,
            label: region.name,
        })),
    }
}

export const transformCountries  = (countries:Country[] ):any[] => {
    if(isEmpty(countries) ) return []
    return countries.map((cItem:Country) => transformCountry(cItem))
}