import { isEmpty } from 'lodash';
import { atom, selector } from 'recoil';
import { transformCountries } from '~/utils/themes';
import { localStorageEffect } from '../storage';
import { AvailableRegions, Countries, Country } from './country.type';

const countries = atom<Countries>({
    key: 'country',
    default: {
        loading: false,
        countries: [],
        countriesHasRegion: [],
        countrySelected: {} as Country,
        regionSelected: {} as AvailableRegions

        // country for aLL site
    },
    effects: [localStorageEffect('country')]
});

const countriesSelector = selector({
    key: 'countries-selector',
    get: ({ get }) => {
        try {
            const currentCountries = get(countries); // This is to ensure the atom is initialized before setting it
            const _countries = transformCountries(currentCountries.countries) || [];
            const firstCountry = _countries?.filter((country: Country) => ['US', 'AU', 'CA'].includes(country.value));
            const newCountries =
                firstCountry.concat(
                    _countries?.filter((country: Country) => !['US', 'AU', 'CA'].includes(country.value))
                ) || [];
            const countryByLocalTime = !isEmpty(currentCountries.countrySelected)
                ? currentCountries.countrySelected
                : newCountries.find((country: Country) => country.is_selected) ||
                  newCountries?.find((item: any) => item.value === 'US');

            const countriesHasRegion =
                _countries
                    ?.filter((country: Country) => !isEmpty(country.available_regions))
                    .map((country: Country) => country.value) || [];
            return {
                ...currentCountries,
                countries: newCountries,
                countriesHasRegion,
                countrySelected: countryByLocalTime,
                regionSelected: currentCountries?.regionSelected ?? countryByLocalTime?.available_regions?.[0]
            };
        } catch (error) {
            console.error(error, 'countries-selector');

            return {
                loading: false,
                countries: [],
                countriesHasRegion: [],
                countrySelected: {} as Country,
                regionSelected: {} as AvailableRegions
            };
        }
    }
});
export default { countriesSelector, countries };
