import Colors from "~/constants/colors";

const config = {
  Common: { starActiveColor: Colors.PRIMARY },
  ProductItem: {
    showDivider: false,
    starBreakSize: ["small"],
    thumbnailRadius: 8,
    badgeStyle: "separated",
  },
  ProductDetail: {
    badgeStyle: "separated",
  },
  Section: {
    dividerPlacement: "embed",
  },
  NewCollection: {
    showItemTitle: false,
  },
};

export default config;
