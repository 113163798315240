import { atom, selector } from "recoil";
import { strip } from "~/utils/number";
import { Cart, CartItem } from "./cart.type";
import { localStorageEffect } from "../storage";

const cart = atom<Cart>({
  key: "cart",
  default: {
    open: false,
    loading: false,
    cartId: null,
    items: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    applied_coupons: [],
    prices: {
      grand_total: {
        value: 0,
        currency: "USD",
        typename: "GrandTotal",
      },
      subtotal_excluding_tax: {
        value: 0,
        currency: "USD",
        typename: "SubtotalExcludingTax",
      },
      subtotal_including_tax: {
        value: 0,
        currency: "USD",
        typename: "SubtotalIncludingTax",
      },
      applied_taxes: [],
      discounts: [],
      typename: "Prices",
    },
  },
  // effects: [localStorageEffect("cart")],
});

const cartSelector = selector({
  key: "cart-selector",
  get: ({ get }) => {
    const currentCart = get(cart);

    // const subtotal = currentCart.items?.map((item) => {
    //       const finalPrice =
    //         item.selectedVariant?.product?.price_range?.minimum_price
    //           ?.final_price?.value || 0;
    //       const quantity = item.quantity || 1;

    //       return finalPrice * quantity;
    //     })
    //     .reduce((sum, item) => sum + item, 0) || 0;

    const subtotal = currentCart?.prices?.subtotal_excluding_tax?.value || 0;

    const shipping_fee = currentCart?.shipping_fee || 0;

    const discount =
      currentCart.prices.discounts?.reduce(
        (total = 0, item) => item.amount.value + total,
        0
      ) || 0;

    const total = subtotal + shipping_fee - discount;

    return {
      ...currentCart,
      discount,
      subtotal,
      total: total < 0 ? 0 : strip(total),
      // totalSelected,
    };
  },
});

export default { cart, cartSelector };
