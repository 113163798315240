import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { Fragment, StrictMode, useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import Colors from '~/constants/colors';
import { AppContext } from '~/contexts/app.context';
import { trackingPageView } from '~/services/tracking';

import { useApollo } from '~/utils/apollo-client';
import { getTheme } from '~/utils/theme';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import AppLayout from '~/layouts/AppLayout';
import MaketingLayout from '~/layouts/MaketingLayout';
import '~/styles/globals.css';

import(`~/styles/${process.env.NEXT_PUBLIC_THEME}.globals.css`).then((res) => res.default);

const AppHead = dynamic(async () => {
    try {
        const component = await import(`~/theme/${getTheme()}/components/AppHead`);
        if (component) {
            return component.default;
        } else {
            return await import('~/theme/base/components/AppHead');
        }
    } catch (error) {
        console.error('Error importing Header:', error);
        throw error;
    }
});

// const AppHead = dynamic(
//   () => import(`~/theme/${process.env.NEXT_PUBLIC_THEME}/components/AppHead`)
// );

// function DebugObserver() {
//     const snapshot: any = useRecoilSnapshot();
//     useEffect(() => {
//         console.debug('The following atoms were modified:');
//         for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
//             console.debug(node.key, snapshot.getLoadable(node));
//         }
//     }, [snapshot]);

//     return null;
// }

export default function App({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const [openSearch, setOpenSearch] = useState(false);
    const apolloClient = useApollo(pageProps.initialApolloState);

    useEffect(() => {
        const handleRouteChange = (route: any) => {
            trackingPageView(route);
        };

        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    const renderComponent = (name: string) => {
        switch (router?.pathname) {
            case '/connect-facebook':
            case '/connect-google':
            case '/recover':
                return <Component {...pageProps} />;
            default: {
                return (
                    <AppLayout>
                        {/* <PageLayout> */}

                        <Component {...pageProps} />

                        {/* </PageLayout> */}
                    </AppLayout>
                );
            }
        }
    };

    return (
        <Fragment>
            <StrictMode>
                <ApolloProvider client={apolloClient}>
                    <AppHead />

                    <RecoilRoot>
                        {/* <DebugObserver /> */}

                        <MaketingLayout />

                        <AppContext.Provider
                            value={{
                                openSearch,
                                setOpenSearch
                            }}
                        >
                            <div className={getTheme()}>
                                {renderComponent(Component.name)}
                                <div className="fixed inset-0 bg-black z-10 app-overlay" />
                            </div>
                        </AppContext.Provider>

                        <RecoilNexus />
                    </RecoilRoot>

                    <NextNProgress color={Colors.PRIMARY} height={3} showOnShallow={false} />
                    <ToastContainer stacked />
                </ApolloProvider>
            </StrictMode>
        </Fragment>
    );
}
